// Westgold Resources Limited

export default {
  settings: {
    disableIframePostMessage: false,
    ctaWhiteBg: "true",
  },
  content: {
    customIntro: "Welcome to the Employee Super Centre",
    general: {
      clientName: "Westgold Resources Limited",
      notAmpFund: true,
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          Westgold Resources Limited Superannuation Fund
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-12">
          <p>
            By joining the company Superannuation fund, you benefit from group discount rates on fees and insurances. This means you pay much less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the Westgold Superannuation Fund. There are helpful super tools and calculators, and information on other services we offer if you require additional support with your financial well-being.
          </p>
          <div class="d-flex justify-content-around btn-wrapper">
            <div class="col-lg-4 pt-4 pb-1 px-0 text-center text-lg-center">
              <a
                target="_blank"
                href="https://auth.ioofonline.com.au/login?state=hKFo2SBCS0FRa3UxeS1nbmZodXZHUDBmWlhfbkxFeWhnYW9iYaFupWxvZ2luo3RpZNkgdUhVZC10OEhPdDFjakt5SDB0NXE1am0xNHIzNDVaeHqjY2lk2SBkODJGVHdXMXJSYmZKTEdaVGNsZmNkcHNVUzFTYlF5ag&client=d82FTwW1rRbfJLGZTclfcdpsUS1SbQyj&protocol=oauth2&scope=openid%20email%20token%3Aexchange&tenant=ioof-production&connection=userstore-ioof-client&audience=authentication-api&redirect_uri=https%3A%2F%2Flogin.portfolioonline.com.au%2Fioof%2Fcallback%2F&badge=ioof&form=login&context=client&timeout=false&isBlocked=false&response_type=code&response_mode=query&nonce=QzRuLTNBcUd1SUtWamNoSkhPRU1GRlV1NVBObUNRdnQyOVYxSi0tZVFiNw%3D%3D&code_challenge=CDgps217cqC9oE7E1gzxsqt2CxikxlPlzYE61X-4ZYU&code_challenge_method=S256&auth0Client=eyJuYW1lIjoiYXV0aDAtc3BhLWpzIiwidmVyc2lvbiI6IjIuMC40In0%3D"
                class="btn-learn-more"
              >
                Log in to IOOF Super Online
              </a>
            </div>
            <div class="col-lg-4 pt-4 pb-1 px-0 text-center text-lg-center">
              <a
                href="https://www.gbsau.dev/gbsau-microsites/westgold-resources-limited/#/#docs"
                class="btn-learn-more"
              >
                LEARN MORE ABOUT THE SUPER PLAN 
              </a>
            </div>
          </div>
        </div>
      </div>
      `,
    pdfs: [
      {
        title: "Overview of the super plan",
        description: "Learn more about the super plan.",
        type: "info",
        url: "Westgold_Super_Plan_Summary.pdf",
      },
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the Westgold Resources fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "Westgold-New_Employee_Pack-April_2024.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "IOOF Online - Registration",
        description: "Find out how to register online with IOOF.",
        type: "info",
        url: "IOOF-Registration-form.pdf",
      },
      {
        title: "IOOF Employer Super Insurance Guide",
        description: "In-depth information on your super fund insurances.",
        type: "info",
        url: "IOOF_Insurance_Guide_01072023.pdf",
      },
      {
        title: "IOOF Documents and Forms",
        description: "Member and Employer forms booklet",
        type: "info",
        externalUrl:
          "https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.ioof.com.au%2Fforms%3Fproduct%3Demployer-super&data=05%7C02%7CMarina_Brunello%40ajg.com.au%7C004a0e9b72194f6bb9be08dc5764f732%7C6cacd170f8974b19ac5846a23307b80a%7C1%7C0%7C638481339555306707%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=51VtMJhv%2F5BufPCIREUZrGtUzpR0oMN%2BX9mpZStMf%2B4%3D&reserved=0",
      },
    ],
    optIn: "IOOF-Insurance-opt-in-form.pdf",
    teamMembers: [
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "sharron.jpg",
        name: "Sharron WhartonStreet",
        phoneHref: "0862508493",
        phone: "08 6250 8493",
        email: "Sharron_WhartonStreet@ajg.com.au",
        social: "https://www.linkedin.com/in/sharron-wharton-street-801026161/",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
    ],
    footerClientName: "Westgold Resources Limited",
  },
  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
